import React from "react";
import { Title } from "@mantine/core";
import background from "./Shop/imageProduit/backgroundhome.webp";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import "./App.css";
import HeaderSimple from "./Header/HeaderSimple";

export default function Home() {
  // Variants pour l'animation de l'image
  const backgroundVariant = {
    hidden: { opacity: 0, scale: 1.1 },
    visible: { opacity: 1, scale: 1, transition: { duration: 2 } },
  };

  const fadeInTextVariant = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 1.5, delay: 0.5 } },
  };

  return (
    <div
      style={{
        position: "relative",
        height: "100vh",
        width: "100vw",
        overflow: "hidden",
      }}
    >
      {/* Animation sur l'image d'arrière-plan */}
      <motion.div
        initial="hidden"
        animate="visible"
        variants={backgroundVariant}
        style={{
          position: "absolute",
          top: "0em",
          left: 0,
          height: "100%",
          width: "100%",
          backgroundImage: `url(${background})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          zIndex: 0, // L'image reste derrière
        }}
      />

      {/* Overlay noir */}
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          height: "100%",
          width: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.4)", // Filtre noir léger
          zIndex: 1, // L'overlay est au-dessus de l'image
        }}
      />

      {/* Header */}
      <HeaderSimple color="white" inCircle="Info" right="portfolio*" />

      {/* Contenu au-dessus de l'image */}
      <div
        style={{
          position: "absolute",
          top: "-17em",
          left: 0,
          height: "100%",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          zIndex: 2, // Le texte est au-dessus de l'overlay
        }}
      >
        <motion.div
          initial="hidden"
          animate="visible"
          variants={fadeInTextVariant}
        >
          <Title
            order={4}
            fw={700}
            style={{
              color: "white",
              fontFamily: "Sequel Sans light",
              fontSize: "1rem",
              transform: "translateY(2.3em) translateX(2.3em)",
              letterSpacing: "-1px",
            }}
          >
            Standsaan*
          </Title>
        </motion.div>

        <motion.div
          initial="hidden"
          animate="visible"
          variants={fadeInTextVariant}
        >
          <Link to="/shop" style={{ textDecoration: "none" }}>
            <Title
              order={1}
              fw={700}
              style={{
                color: "white",
                fontFamily: "Sequel Sans",
                fontSize: "2.3rem",
                letterSpacing: "-1.5px",
                transform: "translateY(-0.7em)",
              }}
            >
              Shop.
            </Title>
          </Link>
        </motion.div>
      </div>
    </div>
  );
}
