import React from "react";
import { Title } from "@mantine/core";
import { motion } from "framer-motion";
import background1 from "./image/aboutme_fonce.webp";
import background2 from "./image/zidane.webp";
import background3 from "./image/kaiba.webp";
import pokemonGif from "./image/gifpokemon.gif";
import respect from "./image/respect.webp";
import nostalgietapis from "./image/nostalgietapis.webp";
import printyour from "./image/printyour.webp";
import merci from "./image/merci.webp";
import buffon from "./image/buffon.webp";
import HeaderSimple from "../Header/HeaderSimple";
import { useMediaQuery } from "@mantine/hooks";

// Composant pour une section d'image pleine page
const ImageSection = ({
  src,
  overlayOpacity = 0,
  animationVariant,
  customBackgroundSize = "cover",
  customHeight = "100vh", // Ajout d'une hauteur personnalisée
  children,
}) => {
  return (
    <motion.div
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true, amount: 0.5 }}
      variants={animationVariant}
      style={{
        height: customHeight, // Utilisation de la hauteur personnalisée
        width: "100vw",
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        overflow: "hidden",
        backgroundImage: `url(${src})`,
        backgroundSize: customBackgroundSize,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: `rgba(0, 0, 0, ${overlayOpacity})`,
          zIndex: 0,
        }}
      />
      <div style={{ zIndex: 1 }}>{children}</div>
    </motion.div>
  );
};

// Composant pour deux images côte à côte ou empilées
const SideBySideImages = ({ leftSrc, rightSrc, leftVariant, rightVariant }) => {
  const isMobile = useMediaQuery("(max-width: 768px)");

  return (
    <div
      style={{
        display: "flex",
        flexDirection: isMobile ? "column" : "row", // Empile les images sur mobile
        width: "100vw",
        height: "100vh",
        overflow: "hidden",
      }}
    >
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.5 }}
        variants={leftVariant}
        style={{
          flex: 1,
          height: isMobile ? "50vh" : "100%",
          backgroundImage: `url(${leftSrc})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      />
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.5 }}
        variants={rightVariant}
        style={{
          flex: 1,
          height: isMobile ? "50vh" : "100%",
          backgroundImage: `url(${rightSrc})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      />
    </div>
  );
};

function Aboutme() {
  const isMobile = useMediaQuery("(max-width: 768px)");

  const fadeInVariant = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 1.5 } },
  };

  const slideInVariant = {
    hidden: { opacity: 0, x: -100 },
    visible: { opacity: 1, x: 0, transition: { duration: 1.5 } },
  };

  const zoomInVariant = {
    hidden: { opacity: 0, scale: 0.8 },
    visible: { opacity: 1, scale: 1, transition: { duration: 1.5 } },
  };

  return (
    <div style={{ overflow: "hidden" }}>
      <HeaderSimple color="#f0dddd" inCircle="Info" right="portfolio*" />

      {/* Première image en pleine page */}
      <ImageSection
        src={background1}
        overlayOpacity={0.3}
        animationVariant={fadeInVariant}
      >
        <Title
          order={1}
          fw={700}
          style={{
            color: "white",
            fontFamily: "Sequel Sans",
            fontSize: isMobile ? "1.5rem" : "2.5rem", // Ajuste la taille du texte sur mobile
            textAlign: "center",
          }}
        >
          Portfolio.-
        </Title>
      </ImageSection>

      {/* Deuxième et troisième images côte à côte */}
      <SideBySideImages
        leftSrc={respect}
        rightSrc={nostalgietapis}
        leftVariant={slideInVariant}
        rightVariant={zoomInVariant}
      />

      {/* Quatrième image en pleine page */}
      <ImageSection
        src={pokemonGif}
        animationVariant={fadeInVariant}
        customBackgroundSize={isMobile ? "contain" : "cover"}
        customHeight={isMobile ? "23vh" : "100vh"} // Hauteur adaptée
      />

      {/* Cinquième et sixième images côte à côte */}
      <SideBySideImages
        leftSrc={background2}
        rightSrc={background3}
        leftVariant={slideInVariant}
        rightVariant={zoomInVariant}
      />

      {/* Septième image en pleine page, avec dézoom */}
      <ImageSection
        src={printyour}
        animationVariant={fadeInVariant}
        customBackgroundSize={isMobile ? "contain" : "cover"}
        customHeight={isMobile ? "46vh" : "100vh"} // Hauteur adaptée
      />

      {/* Huitième et neuvième images côte à côte */}
      <SideBySideImages
        leftSrc={merci}
        rightSrc={buffon}
        leftVariant={slideInVariant}
        rightVariant={zoomInVariant}
      />
    </div>
  );
}

export default Aboutme;
