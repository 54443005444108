import React from "react";
import { Title, Text, Container, Box, Stack } from "@mantine/core";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

function Info() {
  return (
    <Container fluid style={{ padding: 0, height: "100vh", overflowY: "auto" }}>
      {/* Section supérieure */}
      <Box
        style={{
          backgroundColor: "white",
          height: "50vh",
          padding: "2em 4rem",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-around",
          fontFamily: "Sequel Sans",
        }}
      >
        <Stack gap="xl">
          <Title order={1} size="2.3rem" weight={700}>
            <Link
              to="/"
              style={{
                textDecoration: "none",
                color: "black",
                fontWeight: "bold",
              }}
            >
              Preview.
            </Link>
          </Title>
          <Title order={1} size="2.3rem" weight={700}>
            <Link
              to="/aboutme"
              style={{
                textDecoration: "none",
                color: "black",
                fontWeight: "bold",
              }}
            >
              About me. <FontAwesomeIcon icon={faPlus} />
            </Link>
          </Title>
          <Title order={1} size="2.3rem" weight={700}>
            <Link
              to="/shop"
              style={{
                textDecoration: "none",
                color: "black",
                fontWeight: "bold",
              }}
            >
              Shop.
            </Link>
          </Title>
        </Stack>
      </Box>

      {/* Section inférieure */}
      <Box
        style={{
          backgroundColor: "black",
          color: "white",
          height: "50vh",
          padding: "2em 4rem",
          // display: "flex",
          // flexDirection: "column",
          // justifyContent: "space-around",
          fontFamily: "Sequel Sans",
        }}
      >
        <Stack spacing="sm">
          <Title order={1} size="2.3rem" weight={700}>
            Contact.
          </Title>
          <Text size="1rem">@standsaan</Text>
          <Text size="1rem">standsaan@gmail.com</Text>
          <Text size="1rem">
            <FontAwesomeIcon icon={faPlus} />
          </Text>
          <Title order={1} size="2.3rem" weight={700}>
            Services
          </Title>
          <Text size="1rem">Custom typography</Text>
        </Stack>
      </Box>
    </Container>
  );
}

export default Info;
