import React, { useContext } from "react";
import {
  Image,
  Text,
  Button,
  Container,
  Title,
  Divider,
  Grid,
} from "@mantine/core";
import { CartContext } from "./CartContext";
import { CurrencyContext } from "../Shop/CurrencyContext"; // Import du contexte de devise
import HeaderSimple from "../Header/HeaderSimple";
import StripeCheckoutButton from "../StripeCheckoutButton";

const CartDisplay = () => {
  const { cartItems, incrementQuantity, decrementQuantity } =
    useContext(CartContext);
  const { currency } = useContext(CurrencyContext); // Utilise la devise globale

  // Conversion des frais de port
  const shippingCosts = {
    CAD: 15.0,
    EUR: 10.0,
    USD: 10.5,
  };

  const roundToNearestHalf = (num) => Math.round(num * 2) / 2;

  const calculatePrice = (item) => {
    if (item.prix) {
      return item.prix[currency] * item.quantity;
    }
    if (item.prix_formats && item.selectedFormat) {
      return item.prix_formats[item.selectedFormat][currency] * item.quantity;
    }
    return 0;
  };

  const totalPrice = cartItems.reduce(
    (total, item) => total + calculatePrice(item),
    0
  );
  const shippingCost = roundToNearestHalf(shippingCosts[currency]);
  const grandTotal = roundToNearestHalf(totalPrice + shippingCost);

  return (
    <>
      <HeaderSimple color="black" inCircle="Shop" right="cart*" />
      <Container size="xl" style={{ marginTop: "6em", padding: "0 1rem" }}>
        <Title
          align="center"
          style={{ fontFamily: "Sequel Sans", marginBottom: "1.5em" }}
        >
          Cart*
        </Title>
        <Divider my="md" />
        {cartItems.length > 0 ? (
          <>
            <Grid gutter="lg">
              {cartItems.map((item) => (
                <Grid
                  key={item.id}
                  align="center"
                  style={{
                    width: "100%",
                    borderBottom: "1px solid #eaeaea",
                    paddingBottom: "1rem",
                    marginBottom: "1rem",
                  }}
                >
                  <Grid.Col xs={4} sm={3} md={2}>
                    <Image
                      src={item.image[0]}
                      alt={item.nom}
                      radius="md"
                      fit="cover"
                      style={{
                        borderRadius: "8px",
                        width: "100%",
                        maxWidth: "150px",
                        height: "auto",
                      }}
                    />
                  </Grid.Col>
                  <Grid.Col xs={8} sm={4} md={4}>
                    <Text weight={500} style={{ fontFamily: "Sequel Sans" }}>
                      {item.nom}
                    </Text>
                    {item.selectedSize && (
                      <Text size="sm" color="dimmed">
                        Taille : {item.selectedSize}
                      </Text>
                    )}
                    {item.selectedFormat && (
                      <Text size="sm" color="dimmed">
                        Format : {item.selectedFormat}
                      </Text>
                    )}
                  </Grid.Col>
                  <Grid.Col xs={6} sm={3} md={3}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "0.5rem",
                      }}
                    >
                      <Button
                        variant="default"
                        radius="md"
                        onClick={() => decrementQuantity(item.id)}
                        style={{
                          fontSize: "1.5rem", // Taille uniforme pour les deux boutons
                          lineHeight: "1", // Évite les décalages
                          color: "black",
                          padding: "0.5rem",
                          width: "2.5rem", // Largeur et hauteur fixes pour un alignement parfait
                          height: "2.5rem",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        -
                      </Button>
                      <Text
                        size="md"
                        style={{
                          width: "2rem",
                          textAlign: "center",
                        }}
                      >
                        {item.quantity}
                      </Text>
                      <Button
                        variant="default"
                        radius="md"
                        onClick={() => incrementQuantity(item.id)}
                        style={{
                          fontSize: "1.5rem", // Taille uniforme
                          lineHeight: "1",
                          color: "black",
                          padding: "0.5rem",
                          width: "2.5rem", // Largeur et hauteur fixes
                          height: "2.5rem",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        +
                      </Button>
                    </div>
                  </Grid.Col>
                  <Grid.Col xs={6} sm={2} md={3} style={{ textAlign: "right" }}>
                    <Text weight={500} size="lg">
                      {calculatePrice(item).toFixed(2)} {currency}
                    </Text>
                  </Grid.Col>
                </Grid>
              ))}
            </Grid>
            {/* Résumé */}
            <Divider my="lg" />
            <div style={{ textAlign: "right", marginTop: "1rem" }}>
              <Text>
                Sous-total : {totalPrice.toFixed(2)} {currency}
              </Text>
              <Text>
                Frais de port : {shippingCost.toFixed(2)} {currency}
              </Text>
              <Text weight={700} size="lg">
                Total général : {grandTotal.toFixed(2)} {currency}
              </Text>
            </div>
            <div style={{ marginTop: "2rem" }}>
              <StripeCheckoutButton cartItems={cartItems} />
            </div>
          </>
        ) : (
          <Text
            style={{
              fontFamily: "Sequel Sans",
              marginTop: "2rem",
              textAlign: "center",
            }}
          >
            Votre panier est vide*
          </Text>
        )}
      </Container>
    </>
  );
};

export default CartDisplay;
