import React, { useContext, useEffect, useState } from "react";
import { CartContext } from "../Cart/CartContext";
import ProduitCard from "./ProduitCard";
import produits from "./produitData";
import HeaderSimple from "../Header/HeaderSimple";
import { SimpleGrid, Container } from "@mantine/core";
import { CurrencyContext } from "./CurrencyContext";
import { motion } from "framer-motion";

const ProduitListe = () => {
  const { currency } = useContext(CurrencyContext); // Récupère la devise globale
  const [productsWithLocalPrices, setProductsWithLocalPrices] = useState([]);
  const [columns, setColumns] = useState(3); // Nombre de colonnes par défaut
  const [spacing, setSpacing] = useState("lg"); // Espacement par défaut

  // Adapte le nombre de colonnes et le spacing en fonction de la largeur de l'écran
  useEffect(() => {
    const updateLayout = () => {
      const width = window.innerWidth;
      if (width >= 1200) {
        setColumns(3); // Grands écrans
        setSpacing("lg"); // Espacement large
      } else if (width >= 768) {
        setColumns(2); // Tablettes
        setSpacing("md"); // Espacement moyen
      } else {
        setColumns(1); // Petits écrans
        setSpacing("sm"); // Espacement réduit
      }
    };

    updateLayout(); // Définir les colonnes et spacing au chargement initial
    window.addEventListener("resize", updateLayout); // Écouter les changements de taille d'écran
    return () => window.removeEventListener("resize", updateLayout); // Nettoyer l'écouteur
  }, []);

  // Calcule les prix localisés en fonction de la devise
  useEffect(() => {
    const localizedProducts = produits.map((produit) => {
      if (produit.prix) {
        return {
          ...produit,
          prix_local: produit.prix[currency] || produit.prix["EUR"],
        };
      } else if (produit.prix_formats) {
        const prix11x17 =
          produit.prix_formats["11x17"]?.[currency] ||
          produit.prix_formats["11x17"]?.["EUR"];
        return {
          ...produit,
          prix_local: prix11x17,
        };
      }
      return produit;
    });

    setProductsWithLocalPrices(localizedProducts);
  }, [currency]);

  return (
    <>
      <HeaderSimple color="black" inCircle="Shop" right="cart*" />
      <Container size="xl" style={{ marginTop: "6.5em", padding: "0 1rem" }}>
        <SimpleGrid
          cols={columns}
          spacing={spacing} // Espacement dynamique
          verticalSpacing={spacing} // Espacement vertical dynamique
        >
          {productsWithLocalPrices.map((produit, index) => (
            <motion.div
              key={produit.idPage}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
            >
              <ProduitCard
                produit={produit}
                convertedPrice={produit.prix_local}
                currency={currency}
              />
            </motion.div>
          ))}
        </SimpleGrid>
      </Container>
    </>
  );
};

export default ProduitListe;
