import React, { createContext, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const [cartItems, setCartItems] = useState([]);

  const MAX_QUANTITY = 10; // Limite maximale

  const incrementQuantity = (itemId) => {
    setCartItems((currentItems) =>
      currentItems.map((item) => {
        if (item.id === itemId) {
          if (item.quantity < MAX_QUANTITY) {
            return { ...item, quantity: item.quantity + 1 };
          } else {
            toast("Quantité maximale atteinte*", {
              position: "bottom-center",
              autoClose: 1200,
              style: {
                backgroundColor: "black",
                color: "white",
                fontFamily: "Sequel Sans",
                fontSize: "1rem",
              },
              progressStyle: {
                backgroundColor: "white",
              },
            });
            return item;
          }
        }
        return item;
      })
    );
  };

  const decrementQuantity = (itemId) => {
    setCartItems((currentItems) =>
      currentItems.reduce((acc, item) => {
        if (item.id === itemId) {
          if (item.quantity > 1) {
            return [...acc, { ...item, quantity: item.quantity - 1 }];
          }
          toast("Article retiré*", {
            position: "bottom-center",
            autoClose: 1200,
            style: {
              backgroundColor: "black",
              color: "white",
              fontFamily: "Sequel Sans",
              fontSize: "1rem",
            },
            progressStyle: {
              backgroundColor: "white", // Barre de progression blanche
            },
          });
          return acc;
        }
        return [...acc, item];
      }, [])
    );
  };

  const addToCart = (product, quantity, currency, selectedFormat = null) => {
    setCartItems((prevItems) => {
      const existingProductIndex = prevItems.findIndex(
        (item) =>
          item.id === product.id && item.selectedFormat === selectedFormat
      );

      if (existingProductIndex >= 0) {
        const updatedItems = [...prevItems];
        updatedItems[existingProductIndex].quantity += quantity;
        toast("Article ajouté*", {
          position: "bottom-center",
          style: {
            backgroundColor: "black",
            color: "white",
            fontFamily: "Sequel Sans",
            fontSize: "1rem",
          },
          progressStyle: {
            backgroundColor: "white", // Barre de progression blanche
          },
        });
        return updatedItems;
      } else {
        toast("Article ajouté*", {
          position: "bottom-center",
          autoClose: 1200,
          style: {
            backgroundColor: "black",
            color: "white",
            fontFamily: "Sequel Sans",
            fontSize: "1rem",
          },
        });
        return [
          ...prevItems,
          {
            ...product,
            quantity,
            selectedFormat,
          },
        ];
      }
    });
  };

  return (
    <CartContext.Provider
      value={{ cartItems, incrementQuantity, decrementQuantity, addToCart }}
    >
      {children}
    </CartContext.Provider>
  );
};
