import React, { useContext, useEffect, useState } from "react";
import {
  Title,
  Text,
  Button,
  Divider,
  Container,
  Select,
  SimpleGrid,
  Accordion,
} from "@mantine/core";
import { Carousel } from "@mantine/carousel";
import { useParams } from "react-router-dom";
import HeaderSimple from "../Header/HeaderSimple";
import produits from "./produitData";
import { CartContext } from "../Cart/CartContext";
import { CurrencyContext } from "./CurrencyContext";
import "@mantine/carousel/styles.css";

const ProduitDetail = () => {
  const { idPage } = useParams();
  const produit = produits.find((item) => item.idPage === idPage);
  const { addToCart } = useContext(CartContext);
  const { currency } = useContext(CurrencyContext);
  const [selectedSize, setSelectedSize] = useState("");
  const [selectedFormat, setSelectedFormat] = useState("");
  const [price, setPrice] = useState(0);
  const [columns, setColumns] = useState(2);

  useEffect(() => {
    const updateColumns = () => {
      const width = window.innerWidth;
      if (width >= 768) {
        setColumns(2);
      } else {
        setColumns(1);
      }
    };

    updateColumns();
    window.addEventListener("resize", updateColumns);
    return () => window.removeEventListener("resize", updateColumns);
  }, []);

  useEffect(() => {
    if (!produit) return;

    // Si le produit a des formats, calculez le prix pour le format sélectionné
    if (produit.prix_formats) {
      if (selectedFormat) {
        setPrice(produit.prix_formats[selectedFormat]?.[currency] || 0);
      } else {
        // Si aucun format n'est sélectionné, choisissez le moins cher par défaut
        const lowestPriceFormat = Object.keys(produit.prix_formats).reduce(
          (lowest, current) => {
            const currentPrice =
              produit.prix_formats[current]?.[currency] || Infinity;
            const lowestPrice =
              produit.prix_formats[lowest]?.[currency] || Infinity;
            return currentPrice < lowestPrice ? current : lowest;
          },
          Object.keys(produit.prix_formats)[0]
        );
        setSelectedFormat(lowestPriceFormat);
        setPrice(produit.prix_formats[lowestPriceFormat]?.[currency] || 0);
      }
    } else if (produit.prix) {
      // Si le produit n'a pas de formats, utilisez son prix direct
      setPrice(produit.prix[currency]);
    }
  }, [produit, selectedFormat, currency]);

  const handleAddToCart = () => {
    addToCart(
      { ...produit, selectedSize, selectedFormat },
      1,
      currency,
      selectedFormat
    );
  };

  if (!produit) {
    return (
      <Container size="lg" style={{ marginTop: "8em", textAlign: "center" }}>
        <Title order={2}>Produit non trouvé</Title>
        <Text>Veuillez vérifier le produit sélectionné.</Text>
      </Container>
    );
  }

  return (
    <>
      <HeaderSimple color="black" inCircle="Shop" right="cart*" />
      <Container size="lg" style={{ marginTop: "8em", padding: "0 1rem" }}>
        <SimpleGrid
          cols={columns}
          spacing={{ base: "sm", sm: "md", lg: "10em" }} // Espacement selon les breakpoints
        >
          <div>
            <Carousel
              withIndicators
              loop
              height={500}
              slideGap="md"
              breakpoints={[
                { maxWidth: "768px", slideSize: "100%" },
                { maxWidth: "1200px", slideSize: "50%" },
              ]}
              styles={{
                control: {
                  color: "black",
                },
                indicator: {
                  backgroundColor: "#000",
                  width: 10,
                  height: 10,
                  "&[data-active]": {
                    backgroundColor: "#ff6f61",
                  },
                },
              }}
            >
              {produit.image.map((src, index) => (
                <Carousel.Slide key={index}>
                  <img
                    src={src}
                    alt={`${produit.nom} - ${index + 1}`}
                    loading="lazy"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                      borderRadius: "8px",
                    }}
                  />
                </Carousel.Slide>
              ))}
            </Carousel>
          </div>

          <div style={{ textAlign: "left", fontFamily: "Sequel Sans" }}>
            <Title order={1} style={{ marginBottom: "1rem", fontSize: "2rem" }}>
              {produit.nom}
            </Title>
            <Text size="xl" weight={600} style={{ marginBottom: "2rem" }}>
              Prix :{" "}
              {typeof price === "number"
                ? `${price.toFixed(2)} ${currency}`
                : "Non disponible"}
            </Text>
            {produit.tailles && (
              <Select
                label="Choisissez une taille"
                placeholder="Sélectionnez une taille"
                data={produit.tailles}
                value={selectedSize}
                onChange={setSelectedSize}
                style={{ marginBottom: "1rem" }}
              />
            )}
            {produit.formats && (
              <Select
                label="Choisissez un format"
                placeholder="Sélectionnez un format"
                data={produit.formats}
                value={selectedFormat}
                onChange={setSelectedFormat}
                style={{ marginBottom: "1rem" }}
              />
            )}
            <Divider my="lg" />
            <Button
              variant="filled"
              color="rgba(0, 0, 0, 1)"
              onClick={handleAddToCart}
              style={{
                marginTop: "1rem",
                width: "100%",
                height: "3em",
                marginBottom: "1rem",
              }}
              disabled={!selectedSize && produit.tailles}
            >
              Ajouter au Panier
            </Button>

            <Accordion
              defaultValue="description"
              variant="separated"
              style={{ marginTop: "2rem" }}
            >
              <Accordion.Item value="description">
                <Accordion.Control icon={"📄"}>
                  Description Produit
                </Accordion.Control>
                <Accordion.Panel>{produit.description}</Accordion.Panel>
              </Accordion.Item>

              <Accordion.Item value="shipping">
                <Accordion.Control icon={"🚚"}>
                  Informations de livraison
                </Accordion.Control>
                <Accordion.Panel>{produit.description_ship}</Accordion.Panel>
              </Accordion.Item>
            </Accordion>
          </div>
        </SimpleGrid>
      </Container>
    </>
  );
};

export default ProduitDetail;
