import React from "react";
import { Card, Text, Group, Image, useMantineTheme } from "@mantine/core";
import { Link } from "react-router-dom";

const ProduitCard = ({ produit, convertedPrice, currency }) => {
  const theme = useMantineTheme();
  let displayCurrency = "€";

  if (currency === "USD" || currency === "CAD") {
    displayCurrency = "$" + currency;
  }

  return (
    <Link
      to={`/produit/${produit.idPage}`}
      style={{ textDecoration: "none", color: "inherit", marginBottom: "1rem" }}
    >
      <Card
        p={0} // Pas de padding global pour un alignement parfait
        radius="md"
        style={{
          position: "relative",
          maxWidth: "400px",
          margin: "0 auto",
          cursor: "pointer",
          fontFamily: "Sequel Sans",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%",
        }}
      >
        {/* Image Section */}
        <Card.Section
          style={{
            overflow: "hidden", // Empêche l'image de dépasser les coins arrondis
            borderRadius: "8px 8px 0 0", // Ajout explicite pour les coins supérieurs
          }}
        >
          <Image
            src={produit.image[0]}
            alt={produit.nom}
            loading="lazy"
            style={{
              width: "100%",
              height: "500px",
              objectFit: "cover",
            }}
          />
        </Card.Section>

        {/* Footer Section */}
        <div
          style={{
            backgroundColor: "white",
            borderRadius: "0 0 8px 8px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            boxSizing: "border-box",
            marginBottom: "2em",
          }}
        >
          {/* Product Name */}
          <Text
            size="md"
            weight={600}
            style={{
              fontFamily: "Sequel Sans",
              color: theme.colors.dark[9],
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {produit.nom}
          </Text>

          {/* Price */}
          <Text
            size="md"
            weight={600}
            style={{
              fontFamily: "Sequel Sans light",
              color: theme.colors.dark[9],
            }}
          >
            {convertedPrice} {displayCurrency}
          </Text>
        </div>
      </Card>
    </Link>
  );
};

export default ProduitCard;
