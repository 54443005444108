// MaintenancePage.js
import React, { useState } from "react";
import {
  Text,
  Button,
  TextInput,
  Container,
  Title,
  Center,
} from "@mantine/core";

const MaintenancePage = ({ onAccessGranted }) => {
  const [code, setCode] = useState("");
  const [error, setError] = useState(false);

  const handleAccess = () => {
    const accessCode = "kiffance"; // Le code d'accès
    if (code === accessCode) {
      onAccessGranted();
    } else {
      setError(true);
    }
  };

  return (
    <Container size="xs" style={{ marginTop: "10rem", textAlign: "center" }}>
      <Title style={{ fontFamily: "Sequel Sans", marginBottom: "1rem" }}>
        Site en maintenance
      </Title>
      <Text size="sm" color="dimmed" style={{ marginBottom: "2rem" }}>
        Ce site est en maintenance. Veuillez entrer le code pour accéder.
      </Text>
      <TextInput
        placeholder="Code d'accès"
        value={code}
        onChange={(e) => setCode(e.target.value)}
        error={error ? "Code incorrect" : undefined}
        style={{ marginBottom: "1rem" }}
      />
      <Button onClick={handleAccess} fullWidth color="black">
        Accéder au site
      </Button>
    </Container>
  );
};

export default MaintenancePage;
