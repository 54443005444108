import { Button } from "@mantine/core";
import React, { useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { useContext } from "react";
import { CurrencyContext } from "./Shop/CurrencyContext";

const StripeCheckoutButton = ({ cartItems }) => {
  const { currency } = useContext(CurrencyContext); // Utilise la devise globale
  const [loading, setLoading] = useState(false);

  const handleCheckout = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      const lineItems = cartItems.map((item) => {
        let stripePriceId;

        // Identification de l'ID Stripe basé sur le format sélectionné
        if (item.prix_formats && item.selectedFormat) {
          stripePriceId = item.id[item.selectedFormat]?.[currency];
        } else {
          stripePriceId = item.id[currency];
        }

        if (!stripePriceId) {
          throw new Error(
            `ID Stripe introuvable pour le produit ${item.nom} et la devise ${currency}`
          );
        }

        return {
          price: stripePriceId,
          quantity: item.quantity,
        };
      });

      // Ajout des métadonnées basées sur `selectedFormat` et `selectedSize`
      const metadata = cartItems.reduce((acc, item, index) => {
        acc[`item_${index}`] = JSON.stringify({
          name: item.nom,
          size: item.selectedSize || "N/A",
          format: item.selectedFormat || "N/A",
          quantity: item.quantity,
        });
        return acc;
      }, {});

      console.log("Line items envoyés :", lineItems);
      console.log("Metadata envoyées :", metadata);

      const response = await fetch("/create-checkout-session", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ items: lineItems, metadata }),
      });

      if (!response.ok) {
        throw new Error(`Erreur HTTP : ${response.status}`);
      }

      const session = await response.json();
      const stripe = await loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

      if (!stripe) {
        throw new Error("Stripe n'a pas pu être initialisé");
      }

      await stripe.redirectToCheckout({ sessionId: session.id });
    } catch (error) {
      console.error("Erreur lors du traitement :", error.message);
      alert("Une erreur est survenue. Veuillez réessayer.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <form
      onSubmit={handleCheckout}
      style={{
        position: "fixed",
        bottom: 0,
        left: 0,
        width: "100%",
        backgroundColor: "black",
        zIndex: 1000,
      }}
    >
      <Button
        variant="filled"
        color="black"
        type="submit"
        style={{
          width: "100%",
          height: "4rem",
          backgroundColor: "black",
          color: "white",
          fontFamily: "Sequel Sans",
          fontSize: "1.3rem",
        }}
      >
        {loading ? "Chargement..." : "Commander"}
      </Button>
    </form>
  );
};

export default StripeCheckoutButton;
