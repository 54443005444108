import React, { useContext, useState } from "react";
import { CartContext } from "../Cart/CartContext";
import { CurrencyContext } from "../Shop/CurrencyContext";
import { Link, useLocation } from "react-router-dom";
import { Title, Select } from "@mantine/core";
import Info from "../Info";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

function HeaderSimple({ color, inCircle, right }) {
  const { cartItems } = useContext(CartContext);
  const { currency, updateCurrency } = useContext(CurrencyContext);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();

  const headerStyle = {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    boxSizing: "border-box",
    padding: "1.5rem 2.5rem",
    color: color,
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    zIndex: 999, // Priorité élevée pour le header
    height: "6em",
    backgroundColor:
      (location.pathname === "/") |
      (location.pathname === "/aboutme") |
      (location.pathname === "/cancel")
        ? "transparent"
        : "white",
  };

  const linkStyle = {
    textDecoration: "none",
  };

  const titleStyle = {
    fontFamily: "Sequel Sans light",
    fontSize: "1.1rem",
    letterSpacing: "-1.5px",
    color: color,
  };

  const quantity = cartItems.reduce((acc, item) => acc + item.quantity, 0);
  const linkTo = right === "cart*" ? "/cart" : "/aboutme";
  const rightIs = right === "cart*" ? `cart( ${quantity} )*` : "portfolio*";

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  const handleCurrencyChange = (value) => {
    updateCurrency(value);
  };

  return (
    <>
      <header style={headerStyle}>
        {/* Bouton gauche */}
        <div
          className="circle"
          style={{
            justifySelf: "start",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            border: "1px solid " + color,
          }}
        >
          <button
            onClick={toggleMenu}
            style={{ background: "none", border: "none" }}
          >
            <Title order={4} fw={700} style={titleStyle}>
              {inCircle}
            </Title>
          </button>
        </div>

        {/* Sélecteur de devise */}
        <div style={{ justifySelf: "center", zIndex: 1001 }}>
          {location.pathname === "/shop" && (
            <Select
              data={[
                { value: "EUR", label: "EUR (€)" },
                { value: "CAD", label: "CAD (CA$)" },
                { value: "USD", label: "USD ($)" },
              ]}
              value={currency}
              onChange={handleCurrencyChange}
              placeholder="Devise"
              styles={{
                dropdown: {
                  zIndex: 1002, // Assure que le menu de sélection apparaît devant
                },
                input: {
                  fontFamily: "Sequel Sans",
                  fontWeight: 500,
                  border: "1px solid #000",
                  borderRadius: "5px",
                  width: "8rem",
                },
              }}
            />
          )}
        </div>

        {/* Lien de droite */}
        <div style={{ justifySelf: "end" }}>
          <Link to={linkTo} style={linkStyle}>
            <Title order={4} fw={700} style={titleStyle}>
              {rightIs}
            </Title>
          </Link>
        </div>
      </header>

      {/* Menu latéral */}
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          height: "100vh",
          width: "100vw",
          backgroundColor: "white",
          transform: isMenuOpen ? "translateX(0)" : "translateX(-100%)",
          transition: "transform 0.3s ease-in-out",
          zIndex: 1000,
        }}
      >
        {/* Bouton de fermeture */}
        <button
          onClick={toggleMenu}
          style={{
            position: "absolute",
            top: "1rem",
            right: "1rem",
            background: "none",
            border: "none",
            color: "black",
            fontSize: "1.5rem",
            cursor: "pointer",
          }}
        >
          <FontAwesomeIcon icon={faTimes} />
        </button>
        <Info />
      </div>

      {/* Fond semi-transparent */}
      {isMenuOpen && (
        <div
          onClick={toggleMenu}
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            height: "100vh",
            width: "100vw",
            backgroundColor: "rgba(0, 0, 0, 0.1)",
            zIndex: 998,
          }}
        />
      )}
    </>
  );
}

export default HeaderSimple;
