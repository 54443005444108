import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import TagManager from "react-gtm-module"; // Import GTM module
import "./App.css";
import "@mantine/core/styles.css";
import ProduitListe from "./Shop/ProduitListe";
import Portfolio from "./Portfolio/Portfolio";
import Home from "./Home";
import Info from "./Info";
import Aboutme from "./About/Aboutme";
import CartDisplay from "./Cart/CartDisplay";
import Succeed from "./Succeed";
import Error from "./Error";
import ProduitDetail from "./Shop/ProduitDetail";
import MaintenancePage from "./MaintenancePage";
import { MantineProvider } from "@mantine/core";
import { CurrencyProvider } from "./Shop/CurrencyContext";

function App() {
  const [accessGranted, setAccessGranted] = useState(true);

  // Initialiser Google Tag Manager
  useEffect(() => {
    TagManager.initialize({ gtmId: "G-5YDRVRZ25V" }); // Remplacez "GTM-XXXXXXX" par votre ID GTM
  }, []);

  if (!accessGranted) {
    return (
      <MantineProvider>
        <MaintenancePage onAccessGranted={() => setAccessGranted(true)} />
      </MantineProvider>
    );
  }

  return (
    <MantineProvider>
      <CurrencyProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/shop" element={<ProduitListe />} />
            <Route path="/portfolio" element={<Portfolio />} />
            <Route path="/info" element={<Info />} />
            <Route path="*" element={<Home />} />
            <Route path="/aboutme" element={<Aboutme />} />
            <Route path="/cart" element={<CartDisplay />} />
            <Route path="/success" element={<Succeed />} />
            <Route path="/error" element={<Error />} />
            <Route path="/produit/:idPage" element={<ProduitDetail />} />
          </Routes>
        </BrowserRouter>
      </CurrencyProvider>
    </MantineProvider>
  );
}

export default App;
