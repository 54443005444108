import teeDrogba from "./imageProduit/tee-disgrace.webp";
import teedrogbaback from "./imageProduit/tee-fuckingdisgrace.webp";
import teedrogbadetails from "./imageProduit/tee-teeshirtdetails.webp";
import teedrogbadetails2 from "./imageProduit/tee-teeshirtdetails2.webp";

import print1 from "./imageProduit/print1 - 0.webp";
import print1info from "./imageProduit/print1 - 1.webp";
import print1autre from "./imageProduit/print1 - 2.webp";

import teeprintback from "./imageProduit/IMG_6179.webp";
import teeprintinfo from "./imageProduit/1 - 2.webp";
import teeprintinfo2 from "./imageProduit/IMG_6180.webp";

import print2 from "./imageProduit/print2.webp";
import print2info from "./imageProduit/print2 - 1.webp";
import print2autre from "./imageProduit/print2 - 2.webp";

import print3 from "./imageProduit/print3.webp";
import print3info from "./imageProduit/print3 - 1.webp";
import print3autre from "./imageProduit/print3 - 2.webp";
import packprint from "./imageProduit/packprints.webp";

import packprint1 from "./imageProduit/packprint - 1.webp";
import packprint2 from "./imageProduit/packprint - 2.webp";
import teeprint from "./imageProduit/IMG_6178.webp";

const produits = [
  {
    id: {
      "11x17": {
        CAD: "price_1QZA5GIjph6DUuLiP5mNjfpq",
        EUR: "price_1QZA5GIjph6DUuLi7jb5dxdj",
        USD: "price_1QZA5GIjph6DUuLiIu4DWAhY",
      },
      "24x36": {
        CAD: "price_1QZA6uIjph6DUuLiT5inRuEN",
        EUR: "price_1QZA6bIjph6DUuLi8sqvEdQW",
        USD: "price_1QZA7IIjph6DUuLilQ6T5L9y",
      },
    },
    idPage: "pack-3-prints",
    nom: "Pack 3 Prints",
    image: [packprint, packprint1, packprint2],
    prix_formats: {
      "11x17": { CAD: 24.0, EUR: 16.5, USD: 18.0 },
      "24x36": { CAD: 45.0, EUR: 31.0, USD: 31.5 },
    },
    formats: ["11x17", "24x36"],
    stock: true,
    description:
      "Pour les posters, l’impression est réalisée sur un papier semi-brillant.",
    description_ship:
      "Tous les tirages sont soigneusement emballés dans un tube en carton rigide afin de garantir leur protection durant le transport. Ainsi, les œuvres arrivent intactes, sans risque d'endommagement ni de plis. De plus, chaque commande comporte deux patchs respect.",
  },

  // Produit : Tee-shirt + prints
  {
    id: {
      "11x17": {
        CAD: "price_1QZA8iIjph6DUuLitc1ko3qR",
        EUR: "price_1QZXp9Ijph6DUuLixxmNmcER",
        USD: "price_1QZA95Ijph6DUuLiynxkJMQ5",
      },
      "24x36": {
        CAD: "price_1QZAAPIjph6DUuLiKYLP3KSr",
        EUR: "price_1QZXnpIjph6DUuLi9KX1r5U1",
        USD: "price_1QZXoHIjph6DUuLiZhY5YJU8",
      },
    },
    idPage: "tee-shirt-plus-prints",
    nom: "Tee-shirt + prints",
    image: [teeprint, teeprintback, teeprintinfo, teeprintinfo2],
    prix_formats: {
      "11x17": { CAD: 76.5, EUR: 51.5, USD: 53.5 },
      "24x36": { CAD: 97.5, EUR: 65.5, USD: 68 },
    },
    formats: ["11x17", "24x36"],
    prints: ["Print 1", "Print 2", "Print 3"],
    tailles: ["S", "M", "L", "XL", "2XL"],
    stock: true,
    description_ship:
      "Concernant les t-shirts, ceux-ci fonctionnent sous un système de précommande. Il faudra prévoir environ 4 semaines avant de recevoir le produit. Le t-shirt sera emballé dans du papier glacé, lui-même placé dans une enveloppe rembourrée afin qu'il puisse entrer directement dans la boîte aux lettres.Tous les tirages seront soigneusement emballés dans un tube en carton rigide pour garantir leur protection durant le transport. Ainsi, les œuvres arriveront intactes, sans risque d'endommagement ni de plis. De plus, chaque commande comportera deux patchs respect.",
    description:
      "T-shirt 100% coton, coupe oversize 275gsm.Sérigraphie à l'avant, broderie sur la nuque.\
Pour les posters, l’impression est réalisée sur un papier semi-brillant.",
  },
  {
    id: {
      CAD: "price_1QWoVqIjph6DUuLiU6i1hvtR",
      EUR: "price_1QZXlOIjph6DUuLikz6PJNtx",
      USD: "price_1QZXmUIjph6DUuLiA15ELRG7",
    },
    idPage: "tee-shirt",
    nom: "Tee-shirt*",
    image: [teeDrogba, teedrogbaback, teedrogbadetails, teedrogbadetails2],
    prix: { CAD: 53.0, EUR: 35.5, USD: 37.0 },
    stock: true,
    tailles: ["S", "M", "L", "XL", "2XL"],
    description_ship:
      "Concernant les t-shirts, ils fonctionnent sur un système de précommande. Il faudra prévoir environ 4 semaines avant de recevoir le produit. Le t-shirt sera emballé dans du papier glacé, lui-même placé dans une enveloppe rembourrée afin qu'il puisse rentrer directement dans la boîte aux lettres, accompagné de 2 patchs respect.",
    description:
      "T-shirt 100% coton, coupe oversize 275gsm.Sérigraphie à l'avant, broderie sur la nuque.",
  },

  // Produit : Print 1
  {
    id: {
      "11x17": {
        CAD: "price_1QWoQPIjph6DUuLiEiAjPOZN",
        EUR: "price_1QZ9sQIjph6DUuLiktXxwS1b",
        USD: "price_1QZ9skIjph6DUuLi8FQyC5fT",
      },
      "24x36": {
        CAD: "price_1QWoSXIjph6DUuLivThOCjJo",
        EUR: "price_1QZ9wDIjph6DUuLirsKUOukN",
        USD: "price_1QZ9wWIjph6DUuLiISXAeio4",
      },
    },
    idPage: "print-1",
    nom: "It’s a fucking disgrace ( G!!! )",
    image: [print1, print1info, print1autre],
    prix_formats: {
      "11x17": { CAD: 8.0, EUR: 5.5, USD: 6.0 },
      "24x36": { CAD: 15.0, EUR: 10, USD: 10.5 },
    },
    formats: ["11x17", "24x36"],
    stock: true,
    description:
      "Pour les posters, l’impression est réalisée sur un papier semi-brillant.",
    description_ship:
      "Tous les tirages sont soigneusement emballés dans un tube en carton rigide afin de garantir leur protection durant le transport. Ainsi, les œuvres arrivent intactes, sans risque d'endommagement ni de plis. De plus, chaque commande comporte deux patchs respect.",
  },

  // Produit : Print 2
  {
    id: {
      "11x17": {
        CAD: "price_1QWoT8Ijph6DUuLiNNZ7OJ0n",
        EUR: "price_1QZ9xZIjph6DUuLiCxLXqRqS",
        USD: "price_1QZ9xlIjph6DUuLi6l3DDQqG",
      },
      "24x36": {
        CAD: "price_1QWoTfIjph6DUuLiIuC1C2cc",
        EUR: "price_1QZ9ycIjph6DUuLi5yzhCoMx",
        USD: "price_1QZ9ynIjph6DUuLiwDuj5rIf",
      },
    },
    idPage: "print-2",
    nom: "Drogba flocking",
    image: [print2, print2info, print2autre],
    prix_formats: {
      "11x17": { CAD: 8.0, EUR: 5.5, USD: 6.0 },
      "24x36": { CAD: 15.0, EUR: 10, USD: 10.5 },
    },
    formats: ["11x17", "24x36"],
    stock: true,
    description:
      "Pour les posters, l’impression est réalisée sur un papier semi-brillant.",
    description_ship:
      "Tous les tirages sont soigneusement emballés dans un tube en carton rigide afin de garantir leur protection durant le transport. Ainsi, les œuvres arrivent intactes, sans risque d'endommagement ni de plis. De plus, chaque commande comporte deux patchs respect.",
  },

  // Produit : Print 3
  {
    id: {
      "11x17": {
        CAD: "price_1QWoUAIjph6DUuLib88OZHBJ",
        EUR: "price_1QZ9qZIjph6DUuLi9XvV9e4l",
        USD: "price_1QZ9qlIjph6DUuLiGUVRrJU1",
      },
      "24x36": {
        CAD: "price_1QWoUPIjph6DUuLiWwCThlMp",
        EUR: "price_1QZA2yIjph6DUuLiB26Lj5VR",
        USD: "price_1QZA3BIjph6DUuLi99JjIzJ7",
      },
    },
    idPage: "print-3",
    nom: "It’s a fucking disgrace",
    image: [print3, print3autre, print3info],
    prix_formats: {
      "11x17": { CAD: 8.0, EUR: 5.5, USD: 6.0 },
      "24x36": { CAD: 15.0, EUR: 10, USD: 10.5 },
    },
    formats: ["11x17", "24x36"],
    stock: true,
    description:
      "Pour les posters, l’impression est réalisée sur un papier semi-brillant.",
    description_ship:
      "Tous les tirages sont soigneusement emballés dans un tube en carton rigide afin de garantir leur protection durant le transport. Ainsi, les œuvres arrivent intactes, sans risque d'endommagement ni de plis. De plus, chaque commande comporte deux patchs respect.",
  },

  // Produit : Pack 3 Prints
];

export default produits;
